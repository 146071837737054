
import React from 'react'
import "./SectionContact.css";
import {Button} from 'react-bootstrap';
import { Link } from 'react-router-dom'

function SectionContact() {
    return (
        <>
            <div className="container_contact">
				<div className="img_brackets">
						<div className="textos_section">
							
							<h1 className="about-titles">Comienza a integrar equipos en diferentes proyectos de la organización.</h1>
							
							<p className="about-body">Crea proyectos que permitan a los equipos mantenerse alineados a los objetivos y la comunicación en un mismo lugar.</p>

                            <Link to="/ContactoAsana">

                            <Button className="btn_contact" variant="light">Contáctanos</Button>	
                            </Link>

							
							
						</div>
				</div>
	                                                                
	        </div>	
            
        </>
    )
}

export default SectionContact



