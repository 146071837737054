import React from 'react';
import "./AsanaEquipos.css";
import { Carousel, Container } from "react-bootstrap";




function AsanaEquipos() {


  const [alignment, setAlignment] = React.useState(true);

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
    console.log('Aligment ', newAlignment);
  };

  const disableIndicators = [false];

  const [index, setIndex] = React.useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  
  
  return (
    <div id="asana_equipos_id" className='equipos-asana'>

      <div className='container-equipos'>
        <div className='none_color' Style="margin-bottom:20px;">
          <p className="title-asana">Equipos</p>
          <p id="asana_subtitulo"className="sub-asana">Diseñada para todas las Áreas</p>
          <div className="bar"></div>
        </div>



        <div className="slider_asana">
          <div className='containerImg'></div>
        </div>
      </div>






      <div className='responsive-equipos'>

        <div id="responsive-carousel_equipos" className="container">

          <Carousel id="carousel_equipos" className='carousel_id_' activeIndex={index} onSelect={handleSelect} controls="true" indicators={disableIndicators}>
            <Carousel.Item>
              <div className='container_slider'>

              </div>
              <h3 className='h3_titulos'>Marketing</h3>
              <p className='texto_carousel'>Campañas, redes sociales, eventos y mucho más.</p>

            </Carousel.Item>
            <Carousel.Item>
              <div className='container_slider'>
                <h3 className='h3_titulos'>Productos</h3>
                <p className='texto_carousel'>Lanzamientos, plan de producto, seguimiento de actualizaciones.</p>
              </div>

            </Carousel.Item>

            <Carousel.Item>
              <div className='container_slider'>
                <h3 className='h3_titulos'>RRHH</h3>
                <p className='texto_carousel'>Incorporación de nuevos empleados, seguimiento de canales de reclutamiento.</p>
              </div>


            </Carousel.Item>
            <Carousel.Item>
              <div className='container_slider'>
                <h3 className='h3_titulos'>TI</h3>
                <p className='texto_carousel'> Planifica implementaciones. Da seguimiento a solicitudes de servicio de asistencia.</p>
              </div>

            </Carousel.Item>
            <Carousel.Item>
              <div className='container_slider'>
                <h3 className='h3_titulos'>Ventas y Servicios</h3>
                <p className='texto_carousel'>Objetivos de la empresa, solicitudes de trabajo, agendas...</p>
              </div>

            </Carousel.Item>
            <Carousel.Item>

              <div className='container_slider'>
                <h3 className='h3_titulos'>Operaciones</h3>
                <p className='texto_carousel'>Planifica implementaciones. Da seguimiento a solicitudes de servicio de asistencia.</p>
              </div>

            </Carousel.Item>
          </Carousel>





        </div>


      </div>


    </div>
  );
}

export default AsanaEquipos;

