import React from 'react';
import { Form, Button, Col } from 'react-bootstrap';

import "./AsanaContact.css";
//import ReCAPTCHA from "react-google-recaptcha";
import { useState } from 'react';


function AsanaContact() {

  const [verified, isVerified] = useState(false);

  const recaptchaRef = React.createRef();

  const onChange = () => {
      isVerified(true);
  }

  const onSubmit = () => {
      const recaptchaValue = recaptchaRef.current.getValue();
      this.props.onSubmit(recaptchaValue);
  }

  return (
    <div>
      <div className='grid-container'>
        <div className="contacto-asana-header">

          <div class="parent">
            <div class="div1">
              <p id="asana_contact_id">La forma más sencilla de gestionar tareas y proyectos en equipo.</p>

            </div>
            <div class="div2">
              <img

                width="170px" height="95px"
                className="img-asana-contact"
                src="./img/asanapartners.png"
                alt="Asana Partner"
              />
            </div>
          </div>






        </div>
        <div className="contacto-asana-formulario">

          <div className="form-asana">
            
         
                    <Form onSubmit={onSubmit} className="bck-from" action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8" method="POST">
                        <h1 className="sendEmail-h1">
                            Escríbenos para mayor información
                        </h1>
                        <p className="sendEmail-p">Llena todos los campos</p>
                        
                        <input type="hidden" name="oid" value="00D1a000000JpBB"/>
                        <input type="hidden" name="retURL" value="http://www.izeicg.com"/>
                        <input type="hidden" name="recordType" id="recordType" value="0121P000000AYziQAG"/>
                        <input type="hidden" name="lead_source" value="Web"/>
                        <input type="hidden" name="Unidad_de_negocio_multi__c" value="Asana"/>

                       

                        <Form.Group className="padding-from">
                            <Col sm={12}>
                                <Form.Control maxlength="40" name="first_name" for="first_name" id="first_name" className="control-from" type="text" placeholder="Nombre(s)" />
                            </Col>
                        </Form.Group>

                        <Form.Group className="padding-from">
                            <Col sm={12}>
                                <Form.Control id="last_name" maxlength="80" name="last_name" size="20" type="text" className="control-from" placeholder="Apellidos" />
                            </Col>
                        </Form.Group>


                        <Form.Group className="padding-from">
                            <Col sm={12}>
                                <Form.Control className="control-from" type="text" placeholder="Email" id="email" maxlength="80" name="email" size="20" />
                            </Col>

                        </Form.Group>

                        <Form.Group className="padding-from">

                            <Col sm={12}>
                                <Form.Control className="control-from" type="text" placeholder="Teléfono" name="phone" />
                            </Col>

                        </Form.Group>

                        <Form.Group className="padding-from ">
                            <Col sm={12}>
                                <Form.Control className="control-from" placeholder="Empresa" id="company" maxlength="40" name="company" size="20" type="text" />
                            </Col>
                        </Form.Group>

                        <Form.Group className="padding-from" >

                            <Col sm={12}>
                                <Form.Control id="country_code" name="country_code" as="select" className="control-from">

                                    <option value="MX">México</option>
                                    <option value="AR">Argentina</option>
                                    <option value="CA">Canadá</option>
                                    <option value="CL">Chile</option>
                                    <option value="CO">Colombia</option>
                                    <option value="CR">Costa Rica</option>
                                    <option value="EC">Ecuador</option>
                                    <option value="SV">El Salvador</option>
                                    <option value="US">Estados Unidos</option>
                                    <option value="GT">Guatemala</option>
                                    <option value="HN">Honduras</option>
                                    <option value="KY">Islas Caimán</option>
                                    <option value="PA">Panamá</option>
                                    <option value="PE">Perú</option>
                                    <option value="DO">República Dominicana</option>

                                </Form.Control>
                            </Col>

                        </Form.Group>

                        <Form.Group className="padding-from ">
                            {/* 
                            <Col sm={12}>

                              <ReCAPTCHA
                                ref={recaptchaRef}                               
                                sitekey="6LdxeFAgAAAAAI0_arI2emXhyhys_q7eBrulBPoB"
                                onChange={onChange}
                              />

                            </Col>
  */}
                        </Form.Group>

                     

                        <Col sm={12} className="padding-from-btn">

                            {/*<Button disabled={!this.state.isVerified} onClick={window['linkedinclick']} primary type="submit" name="submit" className='btn-style-asana'><b>Enviar</b></Button>*/}
                            {/* disabled={!verified} */}
                            <Button  onClick={window['linkedinclick']} primary type="submit" name="submit" className='btn-style-asana'><b>Enviar</b></Button>

                        </Col>

                    </Form>

               

          </div>

        </div>
        <div className="contacto-asana-porque">
          <table className='asana-table'>
            <thead>
              <tr>
                <th colspan="2"><p className='asana-tab-contacto'>¿Por qué Asana?</p></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><img
                  id='logo-con'
                  className="con-asana-"
                  width="45px"
                  height="45px"
                  
                  src="../img/collabicon.svg"
                  alt="trabajo en equipo icon"
                /></td>
                <td className='td-asana'> <span className='span-table-asana'>Mejora la colaboración entre equipos manteniendo la información en un mismo lugar.</span></td>
              </tr>
              <tr>
                <td><img
                  id='logo-con'
                  width="45px"
                  height="45px"
                  className="img-card"
                  src="../img/calendarioicon.svg"
                  alt="calendario icon"
                /></td>
                <td className='td-asana'><span className='span-table-asana'>Asegúrate que tu equipo conozca lo que tiene que hacer y para cuándo debe de terminar las tareas.</span></td>
              </tr>
              <tr>
                <td><img
                  id='logo-con'
                  width="45px"
                  height="45px"
                  className="img-card"
                  src="../img/multiplataformaicon.svg"
                  alt="multiplataforma icon"
                /></td>
                <td className='td-asana'><span className='span-table-asana'>Mantén la comunicación y fortalece tu equipo reduciendo tiempos en la gestión del trabajo.</span></td>
              </tr>
              <tr>
                <td><img
                  id='logo-con'
                  width="45px"
                  height="45px"
                  className="img-card"
                  src="../img/progresoicon.svg"
                  alt="progreso icon"
                /></td>
                <td className='td-asana'><span className='span-table-asana'>Alinear los objetivos de la organización y visualizar el progreso en una misma plataforma.</span></td>
              </tr>
            </tbody>
          </table>


        </div>
      </div>
    </div>



  );
}

export default AsanaContact;
