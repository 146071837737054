
import React from 'react';
import "./IntegracionAsana.css";


function integracionAsana() {
  return (
    <div id="asana_integraciones_id">
    

      <div className='body-int' >
        <p className="title-asana">Integraciones</p>
        <p className="sub-asana">Conéctate con otras aplicaciones</p>

        <div className="bar"></div>

        <div class="parent-int">
          <div class="div1mt">
            <img src="./img/mt.png" className='responsive-img' alt="Microsoft Teams" />
            <p className='text-int'>Team</p>
          </div>
          <div class="div2">
            <img src="./img/gg.png" className='responsive-img' alt="GMail" />
            <p className='text-int'>Gmail</p>
          </div>
          <div class="div3">
            <img src="./img/gc.png" className='responsive-img' alt="Google Calendar" />
            <p className='text-int'>Calendar</p>
          </div>
          <div class="div4">
            <img src="./img/ss.png" className='responsive-img' alt="Google Spreadsheet" />
            <p className='text-int'>Spreadsheet</p>
          </div>
          <div class="div5">
            <img src="./img/gd1.png" className='responsive-img' alt="Google Drive" />
            <p className='text-int'>Drive</p>
          </div>

          <div class="div6">
            <img src="./img/dp.png" className='responsive-img' alt="Dropbox" />
            <p className='text-int'>Dropbox</p>
          </div>
         
      


        </div>

  

        <div class="parent-int_dos">
          <div class="div1mt">
            <img src="./img/bettercloud.png" width="48px" height="45px" className='responsive-img' alt="BetterCloud" />
            <p className='text-int'>BetterCloud</p>
          </div>
          <div class="div2">
            <img src="./img/figma.png" width="48px" height="45px" className='responsive-img' alt="Figma" />
            <p className='text-int'>Figma</p>
          </div>
          <div class="div3">
            <img src="./img/office365.png" width="48px" height="45px" className='responsive-img' alt="Office Microsoft 365" />
            <p className='text-int'>Office 365</p>
          </div>
          <div class="div4">
            <img src="./img/salesforce.png" width="48px" height="45px" className='responsive-img' alt="Salesforce" />
            <p className='text-int'>Salesforce</p>
          </div>
          <div class="div5">
            <img src="./img/tableau.png" width="48px" height="45px" className='responsive-img' alt="Tableau" />
            <p className='text-int'>Tableau</p>
          </div>
        </div>



        <div className='responsive_parent'>
          <div class="team">
            <img src="./img/mt.png" className='responsive-img' alt="Microsoft Teams " />
            <p className='text-int'>Team</p>
          </div>
          <div class="gmail">
            <img src="./img/gg.png" className='responsive-img' alt="GMail" />
            <p className='text-int'>Gmail</p>
          </div>
          <div class="calendar">
            <img src="./img/gc.png" className='responsive-img' alt="Google Calendar" />
            <p className='text-int'>Calendar</p>
          </div>
          <div class="spread">
            <img src="./img/ss.png" className='responsive-img' alt="Google Spreadsheet" />
            <p className='text-int'>Spreadsheet</p>
          </div>
          <div class="drive">
            <img src="./img/gd1.png" className='responsive-img' alt="Google Drive" />
            <p className='text-int'>Drive</p>
          </div>

          <div class="dropbox">
            <img src="./img/dp.png" className='responsive-img' alt="Dropbox" />
            <p className='text-int'>Dropbox</p>
          </div>

          <div class="office365">
            <img src="./img/office365.png" width="48px" height="45px" className='responsive-img' alt="Office Microsoft 365" />
            <p className='text-int'>Office 365</p>
          </div>
          <div class="salesforce">
            <img src="./img/salesforce.png" width="48px" height="45px" className='responsive-img' alt="Salesforce" />
            <p className='text-int'>Salesforce</p>
          </div>
          <div class="tableau">
            <img src="./img/tableau.png" width="48px" height="45px" className='responsive-img' alt="Tableau" />
            <p className='text-int'>Tableau</p>
          </div>

        </div>

      </div>
    </div>
  );
}




export default integracionAsana;

