import React from 'react';
import { Card, CardDeck } from "react-bootstrap";
import "./WhyAsana.css";


function WhyAsana() {

  const cardBodyStyle = {
    background: '#F3F3F3',
    
  };

  const cardStyle = {
    border: '#F3F3F3'
  }

  return (
    <div className="why_body">
      <div className="cards-asana" id="Id_Cards-asana">
        <h1 id="whyAsana" className="title-h1-asana">¿Por qué Asana?</h1>

        <div id="card-why" className="card__container_asana">
          <div className="card__wrapper_asana">
            <CardDeck>
              <Card style={cardStyle} class="bg-transparent">
                <Card.Body id="cardBodyId" style={cardBodyStyle} className='responsive-card'>
                  <img
                    width="45px"
                    height="45px"
                    className="img-card-asana"
                    src="../img/collabicon.svg"
                    alt="trabajo en equipo icon"
                  />
                  <Card.Text id="cardTextPqAsana" className="card-title">
                  <p id="pCardTextPqAsana" className="card-text-asana">
                  Mejora la colaboración entre equipos manteniendo la información en un mismo lugar.
                  </p>
                  

                  </Card.Text>
                  
                </Card.Body>
              </Card>

              <Card style={cardStyle} className="card-asana">
                <Card.Body id="cardBodyId" style={cardBodyStyle}>
                  <img
                    width="45px"
                    height="45px"
                    className="img-card-asana"
                    src="../img/calendarioicon.svg"
                    alt="calendario icon"
                  />
                  <Card.Text id="cardTextPqAsana" className="card-title">
                  <p id="pCardTextPqAsana" className="card-text-asana">
                  Asegúrate que tu equipo conozca lo que tiene que hacer y para cuándo debe de terminar las tareas.
                  </p>
                  
                  </Card.Text>
                
                </Card.Body>
              </Card>

              <Card style={cardStyle} className="card-asana">
                <Card.Body id="cardBodyId" style={cardBodyStyle}>
                  <img
                    width="45px"
                    height="45px"
                    className="img-card-asana"
                    src="../img/multiplataformaicon.svg"
                    alt="multiplataforma icon"
                  />
                  <Card.Text id="cardTextPqAsana" className="card-title">
                  <p id="pCardTextPqAsana" className="card-text-asana">
                  Mantén la comunicación y fortalece tu equipo reduciendo tiempos en la gestión del trabajo.
                  </p>
                    </Card.Text>
                  
                </Card.Body>
              </Card>
            </CardDeck>
          </div>
        </div>
      </div>
    </div>
  );
}


export default WhyAsana;


