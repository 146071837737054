
import React from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';
import SectionAbout from '../SectionAbout';
import SectionContact from '../SectionContact';
import CardAsana from '../CardAsana';
import IntegracionAsana from '../IntegracionAsana';
import AsanaSoluciones from '../AsanaSoluciones';
import WhyAsana from '../WhyAsana';
import AsanaEquipos from '../AsanaEquipos';



function Home() {
  return (
    <>
      <HeroSection/>
      <CardAsana/>
      <SectionContact/>
      <AsanaEquipos/>
      <AsanaSoluciones/>
      <IntegracionAsana/>
      <WhyAsana/>
      <SectionAbout/>
    </>
  );
}

export default Home;