import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import "./AsanaSoluciones.css";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "bootstrap/dist/css/bootstrap.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

export default function AsanaSoluciones() {
  const [alignment, setAlignment] = React.useState(true);

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
    console.log('Aligment ', newAlignment);
  };





  return (
    <div id="asana_soluciones_id" className='main-container'>

      <p className="title-asana">Soluciones</p>
      <p className="sub-asana">Explora las Soluciones Avanzadas</p>
      <div className="bar"></div>


      <div className='hide-slider-desktop'>

        <div className='slider_container_asana'>

          <div className="container-flex">

            <ToggleButtonGroup
              id='toggle_btn'
              color="primary"
              value={alignment}
              exclusive
              defaultValue={"1"}
              onChange={handleChange}
            >
              <ToggleButton className="custom-btn-left" value="1">
                Automatización de flujo
              </ToggleButton>
              <ToggleButton className="custom-btn-center" value="2">
                Portafolio por Proyecto
              </ToggleButton>
              <ToggleButton className="custom-btn-right" value="3">
                Formularios
              </ToggleButton>
            </ToggleButtonGroup>

          </div>


          <div className='custom-slider'>

            {alignment === '1' ? (
              <div className="container-flujo">

                <div class="grid-img">
                  <img width="381px" height="285px" src="../img/automatizacion_flujos_asana.png" alt="flujos de trabajo"/>
                </div>
                <div id="grid_text_id" class="grid-text">
                  Las reglas en Asana nos permiten simplificar las tareas rutinarias y
                  establecer los flujos de trabajo con facilidad.
                </div>

              </div>
            ) : alignment === '2' ? (

              <div className="container-flujo">

                <div class="grid-img">
                  <img width="381px" height="285px" src="../img/portafolio_proyectos_asana.png" alt="portafolio de proyectos" />
                </div>
                <div id="grid_text_id" class="grid-text">
                  Da seguimiento a los proyectos de tu equipo con una vista única.
                  Establece las prioridades, asigna tareas y un encargado a cada proyecto.
                </div>

              </div>

            ) : (


              <div className="container-flujo">

                <div class="grid-img">
                  <img width="381px" height="285px" src="../img/asana_solicitud_form.png" alt="formulario solicitudes" />
                </div>
                <div id="grid_text_id" class="grid-text">
                  Recopila información dentro de un
                  solo lugar. Conecta a los equipos
                  internos de la organización, cualquier equipo
                  externo, invitados, clientes y
                  proveedores.
                </div>

              </div>


            )}

          </div>


        </div>




      </div>


      <div className='slide-responsive-asana'>

        <div id="responsive-carousel" className="container carousel-wrapper">

          <Carousel className='carousel_id'>
            <div className='init_carousel'>
              <img
                className="card-img-top" src="./img/automatizacion_flujos_asana.png" alt="asanaUno"
              />
              <div>
                <div >
                  <p className="card-text-soluciones">
                    Las reglas en Asana nos permiten simplificar las tareas rutinarias
                    y establecer los flujos de trabajo con facilidad.
                  </p>
                </div>
              </div>
            </div>
            <div className='init_carousel'>
              <img
                className="card-img-top"
                src="./img/portafolio_proyectos_asana.png"
                alt="AsanaDos"
              />
              <div >
                <div>

                  <p className="card-text-soluciones">
                    Da seguimiento a los proyectos de tu equipo con una vista única.
                    Establece los objetivos, asigna tareas y un encargado a cada proyecto.
                  </p>

                </div>
              </div>
            </div>
            <div className='init_carousel'>
              <img
                className="card-img-top"
                src="./img/asana_solicitud_form.png"
                alt="AsanaTres"
              />
              <div>
                <div>

                  <p className="card-text-soluciones">
                    Recopila información dentro de un
                    solo lugar. Conecta a los equipos
                    internos de la organización, cualquier equipo
                    externo, invitados, clientes y
                    proveedores.
                  </p>

                </div>
              </div>
            </div>
          </Carousel>
        </div>

      </div>



    </div>


  );
}


