import "../App.css";
import "./HeroSection.css";

import { Form, Button, Col } from "react-bootstrap";
import React from 'react';
import { useState } from 'react';
//import ReCAPTCHA from "react-google-recaptcha";


function HeroSection() {

    const [verified, isVerified] = useState(false);

    const recaptchaRef = React.createRef();

    const onChange = () => {
        isVerified(true);
    }

    const onSubmit = () => {
        const recaptchaValue = recaptchaRef.current.getValue();
        this.props.onSubmit(recaptchaValue);
    }


    return (
        <div className="hero-container">
            <div className="grid">
                <div className="body">
                    <div className="title">
                        La forma más sencilla de gestionar tareas y proyectos en equipo.
                    </div>

                    <div className="body-s">
                        Brinda a tu equipo todo lo que necesita para mantenerse
                        sincronizado, cumplir con los plazos y alcanzar los objetivos.
                    </div>

                    <div className="logo-style-hero">
                        <img
                            width="230px"
                            height="100px"
                            src="./img/asanapartners.png"
                            alt="logo asana partners"
                        />
                    </div>
                </div>

                <div className="form">
                    <Form onSubmit={onSubmit} className="bck-from" action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8" method="POST">
                        <h1 className="sendEmail-h1">
                            Escríbenos para mayor información
                        </h1>

                        <p className="sendEmail-p">Llena todos los campos</p>

        

                        <input type="hidden" name="oid" value="00D1a000000JpBB"/>
                        <input type="hidden" name="retURL" value="http://www.izeicg.com"/>
                        <input type="hidden" name="recordType" id="recordType" value="0121P000000AYziQAG"/>
                        <input type="hidden" name="lead_source" value="Web"/>
                        <input type="hidden" name="Unidad_de_negocio_multi__c" value="Asana"/>

                     

                        <Form.Group className="padding-from">
                            <Col sm={12}>
                                <Form.Control maxlength="40" name="first_name" for="first_name" id="first_name" className="control-from" type="text" placeholder="Nombre(s)" />
                            </Col>
                        </Form.Group>

                        <Form.Group className="padding-from">
                            <Col sm={12}>
                                <Form.Control id="last_name" maxlength="80" name="last_name" size="20" type="text" className="control-from" placeholder="Apellidos" />
                            </Col>
                        </Form.Group>


                        <Form.Group className="padding-from">
                            <Col sm={12}>
                                <Form.Control className="control-from" type="text" placeholder="Email" id="email" maxlength="80" name="email" size="20" />
                            </Col>

                        </Form.Group>

                        <Form.Group className="padding-from">

                            <Col sm={12}>
                                <Form.Control className="control-from" type="text" placeholder="Teléfono" name="phone" />
                            </Col>

                        </Form.Group>

                        <Form.Group className="padding-from ">
                            <Col sm={12}>
                                <Form.Control className="control-from" placeholder="Empresa" id="company" maxlength="40" name="company" size="20" type="text" />
                            </Col>
                        </Form.Group>

                        <Form.Group className="padding-from" >

                            <Col sm={12}>
                                <Form.Control id="country_code" name="country_code" as="select" className="control-from">

                                    <option value="MX">México</option>
                                    <option value="AR">Argentina</option>
                                    <option value="CA">Canadá</option>
                                    <option value="CL">Chile</option>
                                    <option value="CO">Colombia</option>
                                    <option value="CR">Costa Rica</option>
                                    <option value="EC">Ecuador</option>
                                    <option value="SV">El Salvador</option>
                                    <option value="US">Estados Unidos</option>
                                    <option value="GT">Guatemala</option>
                                    <option value="HN">Honduras</option>
                                    <option value="KY">Islas Caimán</option>
                                    <option value="PA">Panamá</option>
                                    <option value="PE">Perú</option>
                                    <option value="DO">República Dominicana</option>

                                </Form.Control>
                            </Col>

                        </Form.Group>

                        <Form.Group className="padding-from" >
                            {/* 
                            <Col sm={12}>

                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey="6LdpSqgfAAAAAJTbSdJnry_Rsl5IpUUNohO1TCiC"
                                    onChange={onChange}
                                />
                            </Col>
                            */}
                        </Form.Group>


      
                        

                        <Col sm={12} className="padding-from-btn">

                            {/* disabled={!verified} <Button disabled={!this.state.isVerified} onClick={window['linkedinclick']} primary type="submit" name="submit" className='btn-style-asana'><b>Enviar</b></Button>*/}
                            <Button onClick={window['linkedinclick']} primary type="submit" name="submit" className='btn-style-asana'><b>Enviar</b></Button>

                        </Col>

                    </Form>

                </div>

                <div className="logo-bottom"></div>
            </div>
        </div>
    );
}

export default HeroSection;
