

import React from 'react'
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import './SectionAbout.css';
function SectionAbout() {
    return (
        <>





            <div class="container-custom">

                <div class="row">

                    <div id="section_about_logo_asana" class="col-sm-4 col-about">

                        <img className="logo-style-sc" src="./img/izei_logo.png" alt="izei logo 2" />
                    </div>

                    <div class="col-sm-8 colDos">

                        <h5 className="about-h5">Transformación digital</h5>
                        <h1 className="about-h1">Izei Consulting Group</h1>
                        <div className="div-about">
                            Nos respaldan más de 20 años de experiencia en estrategia y soporte para la implementación de mejores prácticas con enfoque de crecimiento y rentabilidad. Tecnología enfocada en diseño e implementación de estrategias digitales y administración del cambio.
                        </div>

                        <div className='line-about'></div>



                        <div className='grid-asana-cards'>

                            <div className='mas10'><span className="custom-cards-title-asana">+10</span></div>
                            <div className='mas15'><span className="custom-cards-title-asana">+15 años</span></div>
                            <div className='mas100'><span className="custom-cards-title-asana">+100</span></div>
                            <div className='operacion'><span className='text-section'> Operación en países de Latinoamérica</span></div>
                            <div className='experiencia'><span className='text-section'>Experiencia en diferentes industrias</span></div>
                            <div className='proyectos'><span className='text-section'> Proyectos de <br></br>transformación</span></div>
                        </div>





                        <div className="btn-bottom">
                            <Link to={{ pathname: "https://www.izeicg.com/" }} target="_blank">
                                <Button className="btn-section-about" >Conoce Más</Button>
                            </Link>

                        </div>


                    </div>


                </div>



            </div>
        </>

    )
}


export default SectionAbout


