
import React from "react";

import { Card, CardDeck } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import './CardAsana.css';


function CardAsana() {

  const cardStyle ={
    background: '#FFFFFF',
    borderRadius: '24px',
    borderColor: '#FFFFFF'
  };

  
  



  return (
    <div id="Acerca_de">

      <div id ="Acerca_de" className="init-card-asana">
        <h1 className="title-h1">Asana</h1>
       
        <p Id="titulo_asana" className="sub-text-card">
          La herramienta de gestión de trabajo que une el contenido y la comunicación en un mismo lugar.
        </p>
        <p className="sub-text-card"> </p>

        <div className="card__container">
          <div className="card__wrapper">
            <CardDeck className="card_deck_asana">
              <Card style={cardStyle} className="card-">
                <Card.Body className="body-card-asana">
                  <img
                    width="47px"
                    height="47px"
                    className="img-card"
                    src="./img/docsicon.svg"
                    alt="docs icon"
                  />
                  <Card.Text className="card-title">
                    <b className="modo_responsivo">Integración de canales</b>
                  </Card.Text>
                  <p className="card-text">Crea, comparte, almacena documentos y archivos.</p>
                </Card.Body>
              </Card>

              <Card style={cardStyle} className="card-">
                <Card.Body className="body-card-asana">
                  <img
                    width="47px"
                    height="47px"
                    className="img-card"
                    src="./img/equiposicon.svg"
                    alt="organización icon"
                  />
                  <Card.Text className="card-title">
                    <b className="modo_responsivo">Gestión del trabajo</b>
                  </Card.Text>
                  <p className="card-text">
                    Creación de planes claros y el seguimiento del progreso
                    hacia las metas.
                  </p>
                </Card.Body>
              </Card>



              <Card style={cardStyle} className="card-">
                <Card.Body className="body-card-asana">
                  <img
                    width="47px"
                    height="47px"
                    className="img-card"
                    src="./img/message.svg"
                    alt="mensajes icon"
                  />
                  <Card.Text className="card-title"><b className="modo_responsivo">Mensajería</b></Card.Text>
                  <p className="card-text">
                    Envía mensajes grupales, individuales y publica anuncios.
                  </p>
                </Card.Body>
              </Card>
            </CardDeck>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardAsana;
