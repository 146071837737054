

import React from 'react'
import './footer.css';
import { Link } from 'react-router-dom';

function footer() {
    return (





      <div className="grid-footer">

        <div className="logo-footer">
              <div className="navigate-logo">

              <img
                  className="img-fluid-izei"
                  src="./img/logo_izei.png"
                  alt="izeiLogo"
                />
              </div>

              <p class="website-rights">
                © 2020 IZEI Consulting Group. 
              </p>
              <Link to={{ pathname: "https://izeitechnology.com/aviso_de_privacidad/" }} target="_blank" class="izei-aviso">Aviso de privacidad</Link>
              

              
               

        </div>

        <div className="wrap-icons-footer">

          <div className="navigate-icons">

            <Link
                  class="social-icon-link facebook"
                  to={{ pathname: "https://www.facebook.com/izeicg/" }} 
                  target="_blank"
                  aria-label="Facebook"
                >
                  <i class="fab fa-facebook-f" />
                </Link>
                <Link
                  class="social-icon-link instagram"
                  to={{ pathname: "https://www.instagram.com/izeicg/" }} 
                  target="_blank"
                  aria-label="Instagram"
                >
                  <i class="fab fa-instagram" />
                </Link>
                <Link
                  class="social-icon-link twitter"
                  to={{ pathname: "https://www.linkedin.com/company/3547276" }} 
                  target="_blank"
                  aria-label="LinkedIn"
                >
                  <i class="fab fa-linkedin" />
            </Link>
           
          </div>
          
              
        </div>
      </div>

    
    );
}

export default footer

