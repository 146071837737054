
import React from 'react'
import './Mensaje.css';

function Mensaje() {
    
    setTimeout(() => {window.location.replace('https://www.izeicg.com/');},3000);

    return (

        <>
            <div className="message_container">
                <div className="message_brackets">
                    <h2 className="text_h2">Mensaje enviado</h2>
                    <h1 className="text_h1">Gracias por contactarnos</h1>
                    <p className="text_p">
                    Hemos recibido tu mensaje correctamente. <br></br>Nos pondremos en contacto a la brevedad
                    </p>
                
                </div>
            </div>
        </>

    )
}

export default Mensaje
