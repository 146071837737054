

import React, {useState} from 'react'
import {Button} from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import './Navbar.css';

function Navbar() {

    const linkTarget = {
        pathname: "/page",
        key: '15', // we could use Math.random, but that's not guaranteed unique.
        state: {
          applied: true
        }
    };

    
    const location = useLocation();
    let history = useHistory();
    let active = false;

    if (location.pathname === "/ContactoAsana" ) {
        active = true;
    }

    const goHome = () => (history.push('/'));
    const [click, setClick] = useState(false);
    const [setButton] = useState(true);
    const handleClick = () => setClick(!click);
    const navTopPage = () => (window.scrollTo(0, 0));
    const closeMobileMenu = () => setClick(false, window.scrollTo(0, 0));
   
    const showButton = () => {
        if(window.innerWidth <=960){
            setButton(false);
        }else{
            setButton(true);
        }
    };

    window.addEventListener('resize', showButton);

 
   

    return (
        <>
        
            <nav className="navbar">
                
                <div className="navbar-container">
                    <div className="navbar-imglogo">
                        <img onClick={active ? goHome : navTopPage} className="img-fluid" src="./img/logo_izei.png" alt="izei logo" />
                     
                    </div>   
                </div>



                <div className="navbar-menu">

                    <div className='menu-icon' onClick={handleClick}>
                        <i className={click ? 'fas fa-times' : 'fas fa-bars'}></i>
                    </div>
                    
                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>

                        <li className='nav-item'>
                            <a href="/" className='nav-links' onClick={closeMobileMenu}> Inicio</a>
                        </li>
                        <li className='nav-item'>
                            <a href="#Acerca_de" className='nav-links' onClick={closeMobileMenu}> Acerca de</a>
                        </li>
                  
                        <li className='nav-item'>
                            <a href="#asana_equipos_id" className='nav-links' onClick={closeMobileMenu}> Equipos</a>
                        </li>

                        <li className='nav-item'>
                            <a href="#asana_soluciones_id" className='nav-links' onClick={closeMobileMenu}> Soluciones</a>
                        </li>

                        <li className='nav-item'>
                            <a href="#asana_integraciones_id" className='nav-links' onClick={closeMobileMenu}> Integraciones</a>
                        </li>
                
                
                        <li>


                       
                         <Link to="/ContactoAsana">
                               
                               <Button onClick={closeMobileMenu} className='btn-con'><b>Contáctanos</b></Button>
                           </Link>
                       
                        </li>
                    </ul>
                </div>
            </nav>
        </>  
    )
}


export default Navbar

